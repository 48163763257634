import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { alpha, brighten } from 'styles/helpers/color'
import { useField, useFormikContext } from 'formik'

import { Button } from 'components/common'
import { BORDER, COLOR, GTR, FONT } from 'styles/tokens'
import Icon from 'components/Icon'

const StyledOptions = styled.div`
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  background: ${COLOR.WHITE};
  z-index: 2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: ${BORDER.RADIUS.M};
  margin-top: ${GTR.M};

  &:before {
    content: '';
    position: absolute;
    top: -15px;
    left: ${GTR.L};
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid ${COLOR.WHITE};
    font-size: 0;
    line-height: 0;
  }
`

const StyledOptionsContainer = styled.div`
  padding: ${GTR.XS};
`

const StyledCustomSelect = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  color: ${COLOR.GREY.DARK};
  font-weight: ${FONT.WEIGHT.MEDIUM};
`

const StyledSelectWrapper = styled.div`
  position: relative;
  user-select: none;
  width: 100%;
`

const StyledSelectTrigger = styled(Button)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 59px;
  line-height: 59px;
  background-color: #ffffff;
  cursor: pointer;
  border-radius: ${BORDER.RADIUS.M};

  && {
    padding: 0 ${parseInt(GTR.S, 10) + parseInt(GTR.XS, 10)}px;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 4px ${alpha(COLOR.WHITE, 0.3)};
  }

  &:active {
    outline: 0;
    box-shadow: 0 0 0 4px ${alpha(COLOR.WHITE, 0.3)};
  }
`

const StyledOption = styled.button`
  position: relative;
  padding: ${GTR.S};
  cursor: pointer;
  border-radius: ${BORDER.RADIUS.S};
  min-height: 59px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  &:hover {
    background-color: ${COLOR.BACKGROUND.PALE};
  }
`

const StyledSubLabel = styled.span`
  display: block;
  font-weight: ${FONT.WEIGHT.REGULAR};
`

const StyledChevron = styled(Icon.Chevron)`
  transform: rotate(90deg);
`

const StyledReset = styled.button`
  background-color: ${COLOR.ACCENT.PRIMARY};
  padding: ${GTR.S} ${parseInt(GTR.S, 10) + parseInt(GTR.XS, 10)}px;
  border-radius: 0 0 ${BORDER.RADIUS.M} ${BORDER.RADIUS.M};
  transition: background-color 0.1s ease-in;
  cursor: pointer;
  width: 100%;
  text-align: left;

  &:hover {
    background-color: ${brighten(COLOR.ACCENT.PRIMARY, -0.2)};
  }
`

const StyledStrongP = styled.p`
  margin: 0;
  font-size: ${FONT.SIZE.S};
`

const StyledRegularP = styled.p`
  font-weight: ${FONT.WEIGHT.REGULAR};
  font-size: ${FONT.SIZE.S};
  margin: 0;
`

const Select = ({ options, placeholder, all, name }) => {
  const [isOpen, setIsOpen] = useState(false)
  const wrapperEl = useRef(null)

  const { handleSubmit } = useFormikContext()
  const [field, , helpers] = useField(name)

  useEffect(() => {
    const setClosed = (e) => {
      if (!wrapperEl.current.contains(e.target)) {
        setIsOpen(false)
      }
    }

    // eslint-disable-next-line
    window.addEventListener('click', setClosed)
    // eslint-disable-next-line
    return () => window.removeEventListener('click', setClosed)
  }, [])

  const handleWrapperClick = () => setIsOpen(!isOpen)

  const handleOptionClick = (event) => {
    const value = event.currentTarget.getAttribute('data-value')
    helpers.setValue(value)
    handleSubmit()
  }

  const findLabel = (value) => {
    if (all && value === 'all') return all
    return (
      options.find((option) => option.value === value)?.label || placeholder
    )
  }

  return (
    <StyledSelectWrapper onClick={handleWrapperClick}>
      <StyledCustomSelect ref={wrapperEl}>
        <StyledSelectTrigger
          aria-haspopup="true"
          aria-expanded={isOpen}
          id={`select-label-${name}`}
        >
          <span>{findLabel(field.value)}</span>
          <StyledChevron width={12} />
        </StyledSelectTrigger>
        {isOpen && (
          <StyledOptions
            aria-labelledby={`select-label-${name}`}
            role="listbox"
            tabindex="0"
            aria-activedescendant={`select-option-${name}-${field.value}`}
          >
            <StyledOptionsContainer>
              {options.map(({ label, subLabel, value }) => (
                <StyledOption
                  key={value}
                  data-value={value}
                  onClick={handleOptionClick}
                  aria-selected={field.value === value}
                  role="option"
                  id={`select-option-${name}-${value}`}
                >
                  <span>{label}</span>
                  {subLabel && <StyledSubLabel>{subLabel}</StyledSubLabel>}
                </StyledOption>
              ))}
            </StyledOptionsContainer>
            {all && (
              <StyledReset
                data-value="all"
                onClick={handleOptionClick}
                aria-selected={field.value === 'all'}
                role="option"
                id={`select-option-${name}-all`}
              >
                <StyledStrongP>Can’t decide where?</StyledStrongP>
                <StyledRegularP>Click here to search everywhere</StyledRegularP>
              </StyledReset>
            )}
          </StyledOptions>
        )}
      </StyledCustomSelect>
    </StyledSelectWrapper>
  )
}

Select.propTypes = {
  all: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

Select.defaultProps = {
  all: '',
}

export default Select
