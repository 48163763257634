import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import omit from 'lodash/omit'
import { Formik, Form } from 'formik'

import JobList from 'components/JobList'
import Layout from 'components/layout'
import SEO from 'components/seo'
import { H, Wrapper, Grid, Button } from 'components/common'
import RegisterWithUs from 'components/RegisterWithUs'
import { COLOR, FONT } from 'styles/tokens'
import Select from 'components/Select'
import Checkbox from 'components/form/Checkbox'
import { screenMax } from 'styles/helpers/responsive'

const StyledWrapper = styled(Wrapper)`
  background-color: ${COLOR.GREY.DARK};
  color: ${COLOR.WHITE};
`

const StyledTotal = styled.p`
  font-size: ${FONT.SIZE.XL};
  font-weight: ${FONT.WEIGHT.BOLD};
  color: ${COLOR.ACCENT.PRIMARY};
`

const StyledViewAllButton = styled(Button)`
  text-decoration: underline;
  font-weight: ${FONT.WEIGHT.REGULAR};

  &:hover {
    text-decoration: underline;
  }
`

const StyledNoJobs = styled(H)`
  line-height: 1.7;
`

const StyledCheckboxWrapper = styled(Grid.Item)`
  ${screenMax.m`
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`

const getLocationOptions = (jobs) => {
  const groupedByLocation = groupBy(jobs, 'address.city')

  const options = map(groupedByLocation, (value) => ({
    value: value[0].address.city,
    label: value[0].address.city,
    subLabel: value[0].address.country,
    total: value.length,
  }))

  return options.sort((a, b) => b.total - a.total)
}

const getCategoryOptions = (groupedJobs) => {
  const options = map(omit(groupedJobs, ['undefined']), (_, key) => ({
    value: key,
    label: key,
  }))

  return [...options, { value: '', label: 'All categories' }]
}

const INITIAL_VALUES = {
  category: '',
  location: '',
  employmentType: ['Permanent', 'Contract'],
}

const Jobs = ({ pageContext: { jobs } }) => {
  const groupedJobs = groupBy(jobs, 'categories.data[0].name')
  const categories = getCategoryOptions(groupedJobs)

  const [filteredJobs, setFilteredJobs] = useState(jobs)

  const filterJobs = (values) => {
    const { location, category, employmentType } = values
    let filtered = jobs

    if (location && location !== 'all') {
      filtered = filtered.filter((job) => {
        return job.address.city === location
      })
    }

    if (category) {
      filtered = filtered.filter((job) => {
        return job.categories.data[0]?.name === category
      })
    }

    filtered = filtered.filter((job) =>
      employmentType.includes(job.employmentType)
    )

    setFilteredJobs(filtered)
  }

  return (
    <Layout>
      <SEO
        title="Design, Tech &amp; Product Jobs"
        description="Looking for work? Browse our Design jobs, peruse our Product roles or be tempted by our Technology careers. Dive in."
      />
      <Formik initialValues={INITIAL_VALUES} onSubmit={filterJobs}>
        {({ handleSubmit, setValues, values }) => {
          const resetForm = () => {
            filterJobs(INITIAL_VALUES)
            setValues(INITIAL_VALUES)
          }

          return (
            <Form>
              <StyledWrapper>
                <Wrapper container containerPaddingBottom="XL">
                  <H size="XL" centered margin={[0, 0, 'XXL']}>
                    Search jobs
                  </H>
                  <Grid margin={[0, 0, 'XL']}>
                    <StyledCheckboxWrapper spanFromL={2} startColumnFromL={2}>
                      <Wrapper>
                        <Wrapper margin={[0, 0, 'XS']}>
                          <Checkbox
                            name="employmentType"
                            value="Permanent"
                            label="Permanent"
                            onChange={handleSubmit}
                          />
                        </Wrapper>
                        <Checkbox
                          name="employmentType"
                          value="Contract"
                          label="Contract"
                          onChange={handleSubmit}
                        />
                      </Wrapper>
                    </StyledCheckboxWrapper>
                    <Grid.Item spanFromL={4}>
                      <Select
                        options={categories}
                        placeholder="Choose job category"
                        name="category"
                        value={values.category}
                      />
                    </Grid.Item>
                    <Grid.Item spanFromL={4}>
                      <Select
                        options={getLocationOptions(jobs)}
                        placeholder="Choose location"
                        name="location"
                        all="Everywhere"
                        value={values.location}
                      />
                    </Grid.Item>
                  </Grid>
                  <Wrapper centered>
                    <StyledTotal>{filteredJobs.length} jobs found</StyledTotal>
                  </Wrapper>
                </Wrapper>
              </StyledWrapper>
              <Wrapper background={COLOR.BACKGROUND.PALE}>
                <Wrapper container>
                  {filteredJobs.length > 0 ? (
                    <JobList jobs={filteredJobs} />
                  ) : (
                    <Wrapper centered>
                      <StyledNoJobs size="S">
                        Sorry, we couldn’t find any jobs that match your search.
                      </StyledNoJobs>
                      <p>
                        Try adjusting the search criteria or{' '}
                        <StyledViewAllButton type="button" onClick={resetForm}>
                          view all jobs
                        </StyledViewAllButton>
                        .
                      </p>
                    </Wrapper>
                  )}
                </Wrapper>
              </Wrapper>
              <RegisterWithUs />
            </Form>
          )
        }}
      </Formik>
    </Layout>
  )
}

Jobs.propTypes = {
  pageContext: PropTypes.shape({
    jobs: PropTypes.array,
  }).isRequired,
}

export default Jobs
